import { atom } from "recoil";
import { parseJSON, getStorage } from "./utils/util";

export const tokenState = atom({
  key: "tokenState",
  default: parseJSON(getStorage().getItem("token")),
});

export const ForumListState = atom({
  key: "ForumListState",
  default: [],
});

export const alarmState = atom({
  key: "alarmState",
  default: { noti: [], load: false },
});

export const headerColorState = atom({
  key: "headerColorState",
  default: null,
});
