import { useNavigate } from "react-router-dom";

const OutResource = ({ children, link, style = {} }) => {
  const navigate = useNavigate();
  const isInnerUrl = (url) => {
    const host = new URL(url).host;
    return host === window.location.host;
  };

  const clickLink = (url) => {
    if (!url) return;
    if (url.startsWith("/")) {
      // 내부 링크
      navigate(url, { replace: true });
    } else if (url.includes(import.meta.env.VITE_APP_MARKET_URL)) {
      window.location.replace(url);
    } else if (isInnerUrl(url)) {
      const navigateUrl = url.split(window.location.host)[1];
      navigate(navigateUrl, { replace: true });
    } else {
      if (window.navigator.userAgent.indexOf("IntegrationWebApp/True") > -1) {
        window.webviewBridge.postMessage(
          JSON.stringify({
            key: "openURL",
            value: url,
          }),
        );
        // eslint-disable-next-line no-empty
      } else {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <div onClick={() => clickLink(link)} style={style}>
      {children}
    </div>
  );
};

export default OutResource;
