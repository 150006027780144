import { Component } from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    if (import.meta.env.VITE_APP_ARG === "prd") {
      Sentry.captureException(error, { extra: info });
      // FIXME navigate
      this.props.navigate("/", { replace: true });
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
