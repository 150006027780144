/* eslint-disable react-refresh/only-export-components */
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { tokenState } from "../state";
import { Img, api, ui, doLogin } from "../utils/util";
import axios from "axios";
import Loading from "./loading";
import { useLocation, useNavigate } from "react-router-dom";

const KakaoLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location && location.search);
  const CLIENT_ID = "41dfd85184340e94222564dcd9f8d11a";
  const REDIRECT_URI = `https://${window.location.host}/sendKakaoToken`;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${location.pathname}`;
  const token = "4343862ba930313b03abdd8b03cf78f4";

  useEffect(() => {
    const js = document.createElement("script");
    js.src = "//developers.kakao.com/sdk/js/kakao.min.js";
    document.body.append(js);

    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(token);
    }
  }, []);

  const setToken = useSetRecoilState(tokenState);

  const sendBridgeKakaoLoginEvent = () => {
    try {
      window.webviewBridge.postMessage(
        JSON.stringify({
          key: "loginWithKakao",
        }),
      );
    } catch (e) {
      return true;
    }
    return false;
  };

  const getQueryString = () => {
    const param = new URLSearchParams(location && location.search);
    const code = param.get("code");

    const data = new URLSearchParams();
    data.append("grant_type", "authorization_code");
    data.append("client_id", "41dfd85184340e94222564dcd9f8d11a");
    data.append("redirect_uri", `https://${window.location.host}/sendKakaoToken`);
    data.append("code", code);

    return data.toString();
  };

  useEffect(() => {
    if (!location.pathname.includes("sendKakaoToken")) return;
    (async () => {
      const queryString = getQueryString();
      const kakaoRes = await axios.post("https://kauth.kakao.com/oauth/token", queryString, {
        headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
      });

      const { pathname, state } = await confirmKakaoToken(
        kakaoRes.data.access_token,
        param.get("state"),
        setToken,
      );
      navigate(pathname, { state: state, replace: true });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <div>
      {location && location.pathname === "/sendKakaoToken" ? (
        <Loading />
      ) : (
        <a id="custom-login-btn" href={KAKAO_AUTH_URL} onClick={sendBridgeKakaoLoginEvent}>
          <img src={Img("", "img/kakao_login.png")} className="width-max" alt="" />
        </a>
      )}
    </div>
  );
};

export default KakaoLogin;

const checkUserInfo = (userInfo) => {
  return (
    userInfo &&
    userInfo.has_birthyear &&
    userInfo.has_birthday &&
    userInfo.has_gender &&
    userInfo.has_phone_number &&
    userInfo.name &&
    userInfo.phone_number.startsWith("+82")
  );
};

export const confirmKakaoToken = async (kakaoToken, pathname, setToken) => {
  window.Kakao.Auth && window.Kakao.Auth.setAccessToken(kakaoToken);
  const data = {
    token: kakaoToken,
  };

  try {
    const verifyRes = await api.post("/user/verifyKakaoToken", data);
    const verifyStatus = verifyRes.data.status;

    if (verifyStatus === "login") {
      setToken({ ...doLogin(verifyRes.data) });
      if (!verifyRes.data.user_type) {
        return Promise.resolve({ pathname: "/verifyPending" });
      }
      return Promise.resolve({ pathname: pathname });
    }

    if (verifyStatus === "sync") {
      setToken({ ...doLogin(verifyRes.data) });

      return Promise.resolve({
        pathname: "/syncAccount",
        state: {
          loginType: "kakao",
          kakaoToken: kakaoToken,
          nickname: verifyRes.data.user_nickname,
          pathname: pathname,
        },
      });
    }

    if (verifyStatus === "failed") {
      const userInfo = verifyRes.data.kakao_token.kakao_account;
      return Promise.resolve(
        checkUserInfo(userInfo)
          ? {
              pathname: "/signup",
              state: {
                kakaoToken: kakaoToken,
                loginType: "kakao",
                birthday: `${userInfo.birthyear}-${userInfo.birthday.slice(
                  0,
                  2,
                )}-${userInfo.birthday.slice(2, 4)}`,
                phone: `0${userInfo.phone_number.slice(4).replaceAll("-", "")}`,
                genderCd: userInfo.gender === "male" ? "M" : "F",
                name: userInfo.name,
              },
            }
          : {
              pathname: "/phoneVerify",
              state: {
                kakaoToken: kakaoToken,
                loginType: "kakao",
              },
            },
      );
    }
  } catch (e) {
    ui.alert(
      "치과의사 면허증의 명의 일치 여부 확인을 위해 면허증과 동일한 명의의 휴대폰 번호 인증이 필요합니다.\n휴대폰 번호가 연동된 카카오 계정으로 재시도 부탁드립니다.",
    );
    // eslint-disable-next-line no-undef
    navigate("/");
  }
};

export const kakaoLogout = () => {
  try {
    window.Kakao.Auth && window.Kakao.Auth.logout();
  } catch (e) {
    console.log("카카오 로그아웃 실패");
    ui.alert("로그아웃을 다시 시도해주세요");
  }
};
